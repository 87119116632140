export default [
  {
    text: 'Home',
    to: '//',
  },

  {
    dropdown: [
      {
        text: 'Mission',
        to: '/about',
      },
      {
        text: 'Vision',
        to: '/about/#mission',
      },
      {
        text: 'Values',
        to: '/about/#vision',
      },
      // {
      //   text: 'Testimonials',
      //   to: '/testimonials',
      // },
      {
        text: 'Pilot Resources',
        to: '/about/#resources',
      },

      {
        text: 'Committee',
        to: '/about/#committee',
      },

      {
        text: 'Committee Login',
        to: '/login',
      },
    ],
    width: '220px',
    to: '/about',
    text: 'About',
  },
  {
    width: '250px',
    text: 'Grants',
    to: '/grants',
  },
  {
    dropdown: [
      {
        text: 'Local Clubs',
        to: '/clubs',
      },
      {
        text: 'Flight Schools & Instructors',
        to: '/flight-school-instructors',
      },
    ],
    width: '250px',
    text: 'Clubs',
  },
  {
    dropdown: [
      {
        text: 'Which Wing?',
        to: '/which-wing',
      },
      {
        text: 'Want to learn?',
        to: '/flight-school-instructors',
      },
      {
        text: 'Looking for a tandem?',
        to: '/flight-school-instructors',
      },
    ],
    width: '220px',
    text: 'Flight Experiences',
  },
  {
    text: 'Gallery',
    to: '/gallery/',
  },
  {
    text: 'Contact',
    to: '/contact-us/',
  },
]
